import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
// import Home from "./Home";
import Entrance from "./Entrance";

function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Me</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Entrance />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
