import React from "react";

function AboutUs() {
  return (
    <div>
      <h1>About Us</h1>
      <p>This is the About Us page.</p>
    </div>
  );
}

export default AboutUs;
