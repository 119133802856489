import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import imagesLoaded from "imagesloaded";
import "./css/base.css";
import image1 from "./images/images-1.png";
import image2 from "./images/images-2.png";
import image3 from "./images/images-3.png";
import image4 from "./images/images-4.png";
import image5 from "./images/images-5.png";
import image6 from "./images/images-6.png";
import image7 from "./images/images-7.png";
import image8 from "./images/images-8.png";

const images = [image1, image2, image3, image4, image5, image6, image7, image8];

const Entrance = () => {
  const sceneRef = useRef(null);
  const groupRef = useRef(null);
  const containerRef = useRef(null);
  const headingsRef = useRef(null);

  useEffect(() => {
    const init = () => {
      const debug = false;
      if (debug) {
        document.querySelector("[data-debug]")?.classList.add("debug");
      }

      const breakPoint = "53em";
      const mm = gsap.matchMedia();

      mm.add(
        {
          isDesktop: `(min-width: ${breakPoint})`,
          isMobile: `(max-width: ${breakPoint})`,
        },
        (context) => {
          let { isDesktop } = context.conditions;

          const cardList = gsap.utils.toArray(".card");
          const count = cardList.length;

          // Increase the radius by 1.5 times
          const radius = isDesktop ? 250 * 1.5 : 180 * 1.5;
          const sliceAngle = (2 * Math.PI) / count;

          gsap.set(cardList, {
            x: (index) =>
              Math.round(radius * Math.cos(sliceAngle * index - Math.PI / 4)),
            y: (index) =>
              Math.round(radius * Math.sin(sliceAngle * index - Math.PI / 4)),
            rotation: (index) => (index + 1) * (360 / count),
          });

          const timeline = gsap
            .timeline()
            .set(cardList, {
              opacity: 0,
              scale: 0,
              x: 0,
              y: 0,
              duration: 2,
            })
            .to(cardList, {
              stagger: 0.15,
              opacity: 1,
              scale: 1.5, // Scale up each card by 1.5
              duration: 3,
              x: (index) =>
                Math.round(radius * Math.cos(sliceAngle * index - Math.PI / 4)),
              y: (index) =>
                Math.round(radius * Math.sin(sliceAngle * index - Math.PI / 4)),
              rotation: (index) => (index + 1) * (360 / count),
            })
            .to(
              groupRef.current,
              {
                rotation: -360 - 90,
                duration: 3,
                ease: "power4.out",
              },
              0
            )
            .from(
              headingsRef.current,
              {
                opacity: 0,
                filter: "blur(60px)",
                duration: 1,
              },
              1
            )
            .to(cardList, {
              repeat: -1,
              duration: 2,
              //   onRepeat: () => {
              //     gsap.to(cardList[Math.floor(Math.random() * count)], {
              //       rotateY: "+=180",
              //     });
              //   },
            })
            // .to(sceneRef.current, {
            //   y: "50vh",
            //   duration: 2,
            //   ease: "power2.inOut",
            // })
            .to(
              containerRef.current,
              {
                rotation: "-=360",
                duration: 60,
                ease: "none",
                repeat: -1,
              },
              0
            );
          // .call(() => {
          //   sceneRef.current.classList.add("scene-moved-down");
          // });

          return () => {};
        }
      );
    };

    // Use imagesLoaded directly
    imagesLoaded(sceneRef.current, { background: true }, () => {
      document.body.classList.remove("loading");
      init();
    });
  }, []);

  return (
    <div className="demo-3">
      <div data-debug>
        <div></div>
      </div>
      <main>
        <header className="frame">
          {/* <h1 className="frame__title">Entrance Animation for Images</h1> */}
          {/* <a
            className="frame__back"
            href="https://tympanus.net/codrops/?p=76798"
          >
            Article
          </a>
          <a
            className="frame__archive"
            href="https://tympanus.net/codrops/demos/"
          >
            All demos
          </a>
          <a
            className="frame__github"
            href="https://github.com/d3adrabbit/EntranceAnimationForImages"
          >
            GitHub
          </a> */}
          {/* <nav className="frame__tags">
            <a href="https://tympanus.net/codrops/demos/?tag=intro">#intro</a>
            <a href="https://tympanus.net/codrops/demos/?tag=stack">#stack</a>
          </nav> */}
          {/* <nav className="frame__demos">
            <a href="index.html">Variation 1</a>
            <a href="index2.html">Variation 2</a>
            <span>Variation 3</span>
          </nav> */}
        </header>
        <div className="content">
          <div className="scene" ref={sceneRef}>
            <div className="container" ref={containerRef}>
              <div className="group" ref={groupRef}>
                {images.map((imgSrc, index) => (
                  <div className="card" key={index}>
                    <div
                      className="card__img"
                      style={{
                        backgroundImage: `url(${imgSrc})`,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="headings" ref={headingsRef}>
            <h1 className="headings__main">OPNRS</h1>
            {/* <h1 className="headings__main gloock-regular">Visions</h1> */}
            <p className="headings_subtitle ">your conversational tool</p>
            <div className="headings_button_container">
              <a
                className="headings_button space-grotesk-medium"
                href="https://testflight.apple.com/join/JQeGgtsH"
              >
                download ios beta
              </a>
            </div>
            <p className="headings_small">Android coming soon</p>
            <p className="headings_link">
              Follow us on Instagram{" "}
              <a href="https://www.instagram.com/opnrs.app" target="_blank">
                @opnrs.app
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Entrance;
